<template>
  <div class="modal-card">
    <div class="modal-content">
      <div class="box">
        <button
          class="modal-close is-absolute is-large"
          aria-label="close"
          @click="$modal.close()"
        />
        <vehicle-carousel
          :photos="data.photos"
          :is-large="true"
          v-if="data.photos.length"
        />
        <listing-card :listing="data" />
        <div class="columns has-text-right">
          <div class="column">
            <span class="button is-text" @click="$modal.close()">Close</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExplorerListing',
  components: {
    vehicleCarousel: () =>
      import('core/modules/vehicle-carousel/components/VehicleCarousel'),
    listingCard: () =>
      import('core/modules/listing-card/components/ListingCard')
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>
